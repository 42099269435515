<template>
  <div>
    <v-card flat>
      <v-tabs v-model="tab" @change="getDataFromApi()" background-color="indigo" grow dark>
        <v-tabs-slider></v-tabs-slider>
        <v-tab href="#Aktif"> Aktif</v-tab>
        <v-tab href="#Yaklasan"> Yaklaşan</v-tab>
        <v-tab href="#Gecmis"> Geçmiş</v-tab>
      </v-tabs>
      <b-overlay :show="loading" rounded="lg" opacity="1">
        <v-card-text>
          <div class="text-right" v-if="tab == 'Gecmis'">
            <b-button
                @click="
                notAttended = !notAttended
                getDataFromApi()
              "
                variant="secondary"
                class="btn btn-hover-light mr-1 pt-0 my-0"
                size="sm"
            >
              <v-switch
                  @click="notAttended = !notAttended"
                  class="mt-0"
                  @change="getDataFromApi()"
                  v-model="notAttended"
                  label="Katılmadıklarım"
                  hide-details
              />
            </b-button>
          </div>
          <div class="pt-2" v-if="rows.length > 0">
            <div
                class="d-flex align-items-center mb-2"
                v-for="item in rows.slice(0, count)"
                :key="item.Id"
            >
              <!-- soldaki içerik -->
              <div class="flex-grow-1 text-left" @click="takeTheExam(item)">
                <a class="text-gray-800 text-hover-primary fw-bolder fs-6 mt-1">
                  <span v-if="tab != 'Yaklasan'">{{ item.Name }}</span>
                  <span v-if="tab == 'Yaklasan'">{{ '...' + item.ExamKind.Name + ' sınavı' }}</span>
                  <br/>
                  <b-badge pill variant="primary"> Türü: {{ item.ExamKind.Name }} <br/></b-badge>
                  <b-badge pill variant="primary" class="mx-1">
                    Süre: {{ item.Duration }} <br/>
                  </b-badge>
                  <b-badge v-if="item.IsOnline == true" pill variant="success">Online</b-badge>
                </a>
                <div class="text-gray-800 text-hover-primary fw-bolder fs-6 mt-1">
                  Sınav Başlangıç: {{ item.StartDate | moment }}
                </div>
                <div class="text-gray-800 text-hover-primary fw-bolder fs-6 mt-1">
                  Sınav Bitiş: {{ item.EndDate | moment }}
                </div>
                <hr/>
              </div>

              <!-- sağdaki butonlar -->
              <b-button
                  variant="secondary"
                  class="btn btn-hover-light mr-1"
                  size="sm"
                  v-if="item.IsPublished"
                  @click="getExamStudentFromApi(item)"
              >Karne
                <inline-svg src="/media/svg/icons/Files/File.svg"></inline-svg>
              </b-button>
              <b-button
                  variant="success"
                  class="btn btn-hover-light"
                  size="sm"
                  v-if="item.IsOnline && tab == 'Aktif'"
                  @click="takeTheExam(item)"
              >Gir
                <inline-svg src="/media/svg/icons/Navigation/Arrow-right.svg"></inline-svg>
              </b-button>
            </div>
            <div class="text-center">
              <b-button v-if="rows.length != totalRows" size="sm" @click="getMoreData"
              >Devamını Yükle
              </b-button
              >
            </div>
          </div>
          <div class="text-center" v-if="rows.length < 1">Sınav bulunamadı</div>
        </v-card-text>
      </b-overlay>
    </v-card>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service.js'
import moment from 'moment'
import Swal from 'sweetalert2'

export default {
  name: 'sinavlar-sayfasi',
  data() {
    return {
      rows: [],
      totalRows: 0,
      loading: true,
      options: {
        itemsPerPage: 5
      },
      tab: null,
      notAttended: false
    }
  },
  filters: {
    moment: function (date) {
      return date ? moment(date).locale('tr').format('Do MMMM YYYY HH:mm') : ''
    }
  },
  mounted() {
    this.getDataFromApi('Aktif')
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true
    }
  },
  methods: {
    getMoreData() {
      this.options.itemsPerPage += 5
    },
    takeTheExam(item) {
      if (item.IsOnline && this.tab == 'Aktif')
        this.$router.push({name: 'sinav', params: {id: item.Id}})
      else {
        Swal.fire({
          toast: true,
          position: 'top',
          icon: 'error',
          title: 'Sınava katılabilmeniz için sınavın aktif ve online olması gerekir',
          showConfirmButton: false,
          timer: 2000
        })
      }
    },
    async getExamStudentFromApi(item) {
      ApiService.setHeader()
      try {
        var tenant = await ApiService.getTenant()
        var data = await ApiService.get('api/StudentExam/ExamStudent/' + item.Id)

        var isSafari =
            navigator.vendor &&
            navigator.vendor.indexOf('Apple') > -1 &&
            navigator.userAgent &&
            navigator.userAgent.indexOf('CriOS') == -1 &&
            navigator.userAgent.indexOf('FxiOS') == -1

        //TODO: TID'lar TenantId olarak değiştirilmeli
        if (data.data.Guid && data.data.Guid.length > 0)
          if (isSafari) {
            window.location.href = 'https://' + tenant.TID + '/sinav-sonucu/' + data.data.Guid
          } else {
            window.open('https://' + tenant.TID + '/sinav-sonucu/' + data.data.Guid, '_blank')
          }
        else {
          Swal.fire({
            toast: true,
            position: 'top',
            icon: 'error',
            title: 'Sınav sonuç kaydı bulunamadı',
            showConfirmButton: false,
            timer: 2000
          })
        }
      } catch (response) {
        ApiService.showError(response.response)
        return
      }
    },
    //this method gets a value for getting the data and doing sth after
    getDataFromApi(value) {
      var model = {}
      //şu anda olan sınavlar
      if (this.tab == 'Aktif') model.IsActive = true
      //3 gün sonraya kadar olan sınavlar
      else if (this.tab == 'Yaklasan') model.IsComing = true
      //geçmiş sınavlar
      else if (this.tab == 'Gecmis') {
        model.IsPast = true
        model.IsPastAndNotAttended = this.notAttended
      }

      this.loading = true
      ApiService.setHeader()
      ApiService.post('api/StudentExam/Exam', {
        sortBy: ['StartDate'],
        descending: [true],
        rowsPerPage: this.options.itemsPerPage,
        page: 1,
        Query: model
      }).then((data) => {
        this.rows = data.data.Results
        this.totalRows = data.data.TotalNumberOfRecords
        this.loading = false

        if (this.rows.length < 1) {
          if (value == 'Aktif' && data.data.Results.length == 0) {
            this.tab = 'Yaklasan'
            this.getDataFromApi('Yaklasan')
          }
          if (value == 'Yaklasan' && data.data.Results.length == 0) {
            this.tab = 'Gecmis'
            this.getDataFromApi()
          }
        }
      })
    }
  }
}
</script>
