var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c(
            "v-tabs",
            {
              attrs: { "background-color": "indigo", grow: "", dark: "" },
              on: {
                change: function ($event) {
                  return _vm.getDataFromApi()
                },
              },
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            [
              _c("v-tabs-slider"),
              _c("v-tab", { attrs: { href: "#Aktif" } }, [_vm._v(" Aktif")]),
              _c("v-tab", { attrs: { href: "#Yaklasan" } }, [
                _vm._v(" Yaklaşan"),
              ]),
              _c("v-tab", { attrs: { href: "#Gecmis" } }, [_vm._v(" Geçmiş")]),
            ],
            1
          ),
          _c(
            "b-overlay",
            { attrs: { show: _vm.loading, rounded: "lg", opacity: "1" } },
            [
              _c("v-card-text", [
                _vm.tab == "Gecmis"
                  ? _c(
                      "div",
                      { staticClass: "text-right" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "btn btn-hover-light mr-1 pt-0 my-0",
                            attrs: { variant: "secondary", size: "sm" },
                            on: {
                              click: function ($event) {
                                _vm.notAttended = !_vm.notAttended
                                _vm.getDataFromApi()
                              },
                            },
                          },
                          [
                            _c("v-switch", {
                              staticClass: "mt-0",
                              attrs: {
                                label: "Katılmadıklarım",
                                "hide-details": "",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.notAttended = !_vm.notAttended
                                },
                                change: function ($event) {
                                  return _vm.getDataFromApi()
                                },
                              },
                              model: {
                                value: _vm.notAttended,
                                callback: function ($$v) {
                                  _vm.notAttended = $$v
                                },
                                expression: "notAttended",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.rows.length > 0
                  ? _c(
                      "div",
                      { staticClass: "pt-2" },
                      [
                        _vm._l(_vm.rows.slice(0, _vm.count), function (item) {
                          return _c(
                            "div",
                            {
                              key: item.Id,
                              staticClass: "d-flex align-items-center mb-2",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "flex-grow-1 text-left",
                                  on: {
                                    click: function ($event) {
                                      return _vm.takeTheExam(item)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "text-gray-800 text-hover-primary fw-bolder fs-6 mt-1",
                                    },
                                    [
                                      _vm.tab != "Yaklasan"
                                        ? _c("span", [
                                            _vm._v(_vm._s(item.Name)),
                                          ])
                                        : _vm._e(),
                                      _vm.tab == "Yaklasan"
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                "..." +
                                                  item.ExamKind.Name +
                                                  " sınavı"
                                              )
                                            ),
                                          ])
                                        : _vm._e(),
                                      _c("br"),
                                      _c(
                                        "b-badge",
                                        {
                                          attrs: {
                                            pill: "",
                                            variant: "primary",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " Türü: " +
                                              _vm._s(item.ExamKind.Name) +
                                              " "
                                          ),
                                          _c("br"),
                                        ]
                                      ),
                                      _c(
                                        "b-badge",
                                        {
                                          staticClass: "mx-1",
                                          attrs: {
                                            pill: "",
                                            variant: "primary",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " Süre: " +
                                              _vm._s(item.Duration) +
                                              " "
                                          ),
                                          _c("br"),
                                        ]
                                      ),
                                      item.IsOnline == true
                                        ? _c(
                                            "b-badge",
                                            {
                                              attrs: {
                                                pill: "",
                                                variant: "success",
                                              },
                                            },
                                            [_vm._v("Online")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-gray-800 text-hover-primary fw-bolder fs-6 mt-1",
                                    },
                                    [
                                      _vm._v(
                                        " Sınav Başlangıç: " +
                                          _vm._s(
                                            _vm._f("moment")(item.StartDate)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-gray-800 text-hover-primary fw-bolder fs-6 mt-1",
                                    },
                                    [
                                      _vm._v(
                                        " Sınav Bitiş: " +
                                          _vm._s(
                                            _vm._f("moment")(item.EndDate)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("hr"),
                                ]
                              ),
                              item.IsPublished
                                ? _c(
                                    "b-button",
                                    {
                                      staticClass: "btn btn-hover-light mr-1",
                                      attrs: {
                                        variant: "secondary",
                                        size: "sm",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.getExamStudentFromApi(item)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v("Karne "),
                                      _c("inline-svg", {
                                        attrs: {
                                          src: "/media/svg/icons/Files/File.svg",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              item.IsOnline && _vm.tab == "Aktif"
                                ? _c(
                                    "b-button",
                                    {
                                      staticClass: "btn btn-hover-light",
                                      attrs: { variant: "success", size: "sm" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.takeTheExam(item)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v("Gir "),
                                      _c("inline-svg", {
                                        attrs: {
                                          src: "/media/svg/icons/Navigation/Arrow-right.svg",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        _c(
                          "div",
                          { staticClass: "text-center" },
                          [
                            _vm.rows.length != _vm.totalRows
                              ? _c(
                                  "b-button",
                                  {
                                    attrs: { size: "sm" },
                                    on: { click: _vm.getMoreData },
                                  },
                                  [_vm._v("Devamını Yükle ")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm.rows.length < 1
                  ? _c("div", { staticClass: "text-center" }, [
                      _vm._v("Sınav bulunamadı"),
                    ])
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }